import './title.css';

function Title(){

return (
  <div className="Heading">
    <div className='scot-football-header'>
  <h1>
  <span className="scottish-football">SCOTTISH</span></h1>
  
  <h1>
  <span className="scottish-football">FOOTBALL</span></h1>
    </div>
  <h1>
  <span className="simulators">Simulators</span></h1>
</div>
);
}

export default Title;